/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { endsWith, isEmpty, size, values } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

import { useFieldArray, useForm } from 'react-hook-form';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import appConfig from '../../app/appConfig';
import ShadowSelect from '../../app/component/ShadowSelect';
import usePersistAppConfig from '../../app/use-persist-app-state';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import Textarea from '../../components/form/Textarea';
import Modal from '../../components/Modal';
import AquaticsSelector from '../../components/selector/AquaticsSelector';
import RegionSelector from '../../components/selector/RegionSelector';
import {
  Enum_Post_Category,
  useCreateTradePostMutation,
  useUpdateTradePostMutation,
  useUploadMutation,
} from '../../generated/graphql';
import { convToRender, TPost, TTrade } from '../../types/gql-enhanced-types';
import { notEmpty, notNull } from '../../utils/array';
import { getMediaUrl } from '../../utils/image';
import { toValidNumber } from '../../utils/number';
import useAuth from '../auth/use-auth';

const { imageMaxCount } = appConfig.post;

type FormData = {
  postData: {
    id?: null | string | undefined;
    desc: string | null | undefined;
    shadower?: string | null | undefined;
    images: (File | string)[];
    previews: string[];
    category?: Enum_Post_Category;
  };
  trade: {
    regionsChecked?: boolean;
    regionsJsonStr?: string | null;
  };
  trades: {
    aquatic?: null | string | undefined;
    name?: string | null;
    price?: string;
    quantity?: string;
  }[];
};

function ModalTradePostForm({
  post: nPost,
  trade,
  onClose,
  onSuccess,
  category,
}: {
  post?: TPost;
  trade?: TTrade;
  onClose: () => void;
  onSuccess?: () => void;
  category?: Enum_Post_Category;
}) {
  const [{ userId: creatorId, isSuperUserOrShadow, isSuperAdmin, user }] =
    useAuth();

  const [{ shadowNames }, { removeShadowName, addShadowName }] =
    usePersistAppConfig();

  const post = nPost || convToRender(trade?.post?.data);
  const isEdit = !!trade;

  useModalManage({
    modalKey: 'trade-create',
    onBlocked: () => {
      onClose();
    },
  });

  const initData: FormData = useMemo(() => {
    if (!post?.id)
      return {
        postData: {
          id: '',
          desc: '',
          images: [],
          previews: [],
          category: category || Enum_Post_Category.Trade,
        },
        trade: {
          regionsJsonStr: '',
          regionsChecked: false,
        },
        trades: [],
      };

    const imagesIds = post?.images?.data.map((d) => d.id).filter(notNull) || [];
    const imagesData = post?.images?.data || [];
    const previews = imagesData
      .map((d) => getMediaUrl(d.attributes?.url))
      .filter(notNull);

    const trades = trade?.aquatic?.data?.id
      ? [
          {
            name: trade.aquatic?.data?.attributes?.name,
            aquatic: trade.aquatic?.data?.id,
            price: `${trade.price || ''}`,
            quantity: `${trade.quantity || ''}`,
          },
        ]
      : [];

    return {
      postData: {
        id: post.id || '',
        desc: post?.desc || '',
        images: imagesIds,
        previews,
      },
      trade: {
        regionsJsonStr: trade?.regionsJsonStr || '',
        regionsChecked: trade?.regionsChecked || false,
      },
      trades,
    };
  }, [trade]);

  const { handleSubmit, setValue, control, register, watch } =
    useForm<FormData>({
      defaultValues: initData,
    });

  const regionsJsonStr = watch('trade.regionsJsonStr');
  const regionsChecked = watch('trade.regionsChecked');
  const shadower = watch('postData.shadower');

  const regions = useMemo<string[]>(() => {
    try {
      return JSON.parse(regionsJsonStr || '[]');
    } catch (error) {
      //
    }
    return [];
  }, [regionsJsonStr]);

  const { append, remove } = useFieldArray({
    control,
    name: 'trades',
    rules: {
      required: {
        value: true,
        message: '판매 어종을 선택해주세요.',
      },
    },
  });

  const postData = watch('postData');
  const trades = watch('trades');
  const { previews, images } = postData;

  const imagesAddedCount = size(images);
  const leftToAdd = imageMaxCount - imagesAddedCount;

  const [saving, setSaving] = useState(false);
  const [upload] = useUploadMutation({
    onError() {
      toast.error('업로드가 실패했어요. 나중에 다시 시도해주세요.');
      setSaving(false);
    },
  });

  const [createTradePost] = useCreateTradePostMutation({
    refetchQueries: ['Posts', 'Trades'],
    onCompleted: () => {
      toast.success('등록완료');

      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError() {
      toast.error('글 동록 실패. 나중에 다시 시도해주세요.');
      setSaving(false);
    },
  });

  const [updateTradePost] = useUpdateTradePostMutation({
    onCompleted: () => {
      toast.success('등록완료');
      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError() {
      setSaving(false);
    },
  });

  const onDrop = useCallback(
    async (nFiles: File[]) => {
      if (saving) return;

      const imageFiles = nFiles
        .filter((f) => {
          return !endsWith(f.name, 'gif');
        })
        .slice(0, leftToAdd);

      const nPreviews = imageFiles.map((f) => URL.createObjectURL(f));

      if (size(nPreviews) > 0) {
        setValue('postData.images', [...images, ...imageFiles]);
        setValue('postData.previews', [...previews, ...nPreviews]);
      }
    },
    [saving, setValue],
  );

  async function handleSave() {
    if (saving) return;

    if (isEmpty(postData.images)) {
      toast.warn('이미지 없이는 올릴 수 없어요 ㅠ');
      return;
    }

    setSaving(true);

    const resp = await Promise.all(
      postData.images.map((file) => {
        if (typeof file === 'string') {
          return file;
        }

        try {
          return upload({
            variables: {
              file,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('file upload error', error);
          return null;
        }
      }),
    );

    const uploadIds = resp
      .map((r) => {
        if (typeof r === 'string') {
          return r;
        }
        return r?.data?.upload?.data?.id;
      })
      .filter(notNull);

    if (isEmpty(uploadIds)) {
      setSaving(false);
      toast.error('이미지 업로드 실패. 다시 시도해주세요.');
      return;
    }

    const data = {
      images: uploadIds,
      desc: postData?.desc,
      creator: creatorId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    // updating
    if (postData.id) {
      await updateTradePost({
        variables: {
          id: postData.id,
          data,
          trades: trades.map((trItem) => ({
            aquatic: trItem.aquatic,
            creator: creatorId,
            price: toValidNumber(trItem.price),
            quantity: toValidNumber(trItem.quantity),
            regionsChecked,
            regionsJsonStr,
          })),
        },
      });
      return;
    }

    data.category = postData.category || Enum_Post_Category.Trade;

    if (!isSuperAdmin) {
      delete data.shadower;
    } else if (postData.shadower) {
      data.shadower = postData.shadower;
      addShadowName(data.shadower);
    }

    // CREATING
    // create post with image id
    await createTradePost({
      variables: {
        data,
        trades: trades.map((trItem) => ({
          aquatic: trItem.aquatic,
          price: toValidNumber(trItem.price),
          quantity: toValidNumber(trItem.quantity),
          regionsChecked,
          regionsJsonStr,
        })),
      },
    });
  }

  const imgSelected = !isEmpty(postData.images);

  return (
    <Modal open>
      <div className='flex py-2 flex-center-y'>
        <div className='flex-none w-0 overflow-visible z-40'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              const yes = window.confirm(
                '작성중인 내용이 사라집니다. 나가시겠습니까?',
              );
              if (!yes) return;

              onClose();
            }}
          >
            <MdArrowBack size={32} />
          </button>
        </div>

        <div className='flex-1 text-center text-modal-title'>분양 글</div>

        <div className='flex-none w-0 overflow-visible z-40'>
          <div className='relative bg-red-300 h-[40px]'>
            <div className='absolute right-0 -top-1'>
              <button
                className='btn btn-ghost whitespace-nowrap btn-lg flex flex-col'
                onClick={() => {
                  handleSubmit(handleSave, (e) => {
                    values(e).forEach((msg) => {
                      if (typeof msg === 'object') {
                        if (msg.message) {
                          toast.error(msg.message);
                        }

                        values(msg).forEach((msg2) => {
                          const mm = msg2 as { message?: string };
                          if (mm.message) {
                            toast.error(mm.message);
                          }

                          values(mm).forEach((msg3) => {
                            const mmm = msg3 as { message?: string };
                            if (mmm.message) {
                              toast.error(mmm.message);
                            }
                          });
                        });
                      }
                    });
                  })();
                }}
              >
                {saving ? (
                  <span className='loading loading-spinner'></span>
                ) : isEdit ? (
                  '수정'
                ) : (
                  '동록'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-1 px-4 flex-col gap-9 overflow-auto'>
        {isSuperUserOrShadow && (
          <div className='flex-col gap-2'>
            <label htmlFor=''>현재유저: {user?.displayName}</label>
          </div>
        )}
        {isSuperAdmin && (
          <>
            <div className='flex-col gap-2'>
              <label htmlFor=''>작성자</label>

              <ShadowSelect
                value={shadower ? { value: shadower, label: shadower } : null}
                onChange={(o) => {
                  setValue('postData.shadower', o?.value);
                }}
              />
              <div className='flex gap-2 flex-wrap'>
                {shadowNames?.filter(notEmpty)?.map((name, idx) => {
                  return (
                    <span
                      key={`${name}-${idx}`}
                      className='badge badge-primary badge-lg whitespace-nowrap cursor-pointer'
                      onClick={() => {
                        setValue('postData.shadower', name);
                      }}
                    >
                      {name}
                      <span
                        onClick={() => {
                          removeShadowName(name);
                        }}
                      >
                        <IoMdCloseCircle size={20} className='opacity-50' />
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
          </>
        )}

        <div className='flex-col gap-3'>
          <label htmlFor=''>생물 이름 *</label>
          <div className='flex-col gap-2 text-sm'>
            {isEdit ? (
              <div className='opacity-60'>
                * 어종 수정은 불가합니다. 수량, 가격, 설명 만 가능.
              </div>
            ) : (
              <div className='opacity-60'>
                <span>
                  * 한 종류의 생물만 선택/등록 하세요. 여러 이름을 한 종류로
                  등록 시 제재 대상이 됩니다. <br />* 구매자들에게 알림도
                  보내지지 않습니다. (예: 디스커스와 코리도라스)
                </span>
              </div>
            )}

            <div className='flex-col gap-2'>
              {trades.map((trItem, idx) => {
                const total =
                  (toValidNumber(trItem.quantity) || 1) *
                  (toValidNumber(trItem.price) || 1);

                return (
                  <div
                    key={trItem.aquatic}
                    className='p-3 border-2 rounded flex bg-primary bg-opacity-10'
                  >
                    <div className='flex-1 flex-col gap-2'>
                      <div className='font-bold text-xl'>{trItem.name}</div>
                      <div className='flex-1 flex gap-2'>
                        <div className='flex-1'>
                          <div className='form-control w-full max-w-xs'>
                            <label className='label'>
                              <span className='label-text'>단가</span>
                            </label>
                            <div className='join w-full'>
                              <input
                                type='number'
                                className='input w-full join-item '
                                placeholder='단가'
                                onFocus={(e) => {
                                  if (e.target.value === '5000') {
                                    e.target.select();
                                  }
                                }}
                                {...register(`trades.${idx}.price`, {
                                  required: {
                                    value: true,
                                    message: '가격은 필수입니다.',
                                  },
                                })}
                              />
                              <label className='join-item rounded-r p-2 flex-center-y bg-white border-t border-b border-r border-black border-opacity-20'>
                                원
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='flex items-end pb-3'>X</div>
                        <div className='w-1/3'>
                          <label className='label'>
                            <span className='label-text'>수량</span>
                          </label>

                          <input
                            type='number'
                            placeholder='수량'
                            className='input w-full'
                            onFocus={(e) => {
                              if (e.target.value === '1') {
                                e.target.select();
                              }
                            }}
                            {...register(`trades.${idx}.quantity`, {
                              required: {
                                value: true,
                                message: '수량은 필수입니다.',
                              },
                            })}
                          />
                        </div>
                      </div>
                      <label className='label'>
                        <span className='label-text'></span>
                        {total && trItem.price && trItem.quantity && (
                          <span className='label-text text-xl'>
                            총 {total.toLocaleString()} 원
                          </span>
                        )}
                      </label>
                    </div>
                    {!isEdit && (
                      <div className='flex-center-y flex-shrink-0'>
                        <button
                          className='btn btn-sm btn-ghost pr-1'
                          onClick={() => {
                            remove(idx);
                          }}
                        >
                          <IoMdCloseCircle size={23} className='opacity-50' />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {!trades?.[0] && (
              <AquaticsSelector
                placeholder='생물을 검색. ex) 디스커스, 코리도라스'
                onChange={(e) => {
                  if (!e?.value) return;

                  append({
                    aquatic: e?.value,
                    name: e?.label,
                    quantity: '1',
                    price: '5000',
                  });
                }}
              />
            )}
          </div>
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>지역 *</label>
          {isEdit && (
            <div>지역 수정이 필요한 경우 글 삭제 후 다시 올려주세요. </div>
          )}
          <RegionSelector
            {...register('trade.regionsJsonStr', {
              required: {
                value: true,
                message: '지역은 필수입니다.',
              },
            })}
            disabled={isEdit}
            value={regions}
            onChange={(e) => {
              if (notEmpty(e)) {
                const nValues = e.map((v) => v.value);
                setValue('trade.regionsJsonStr', JSON.stringify(nValues));
                return;
              }

              setValue('trade.regionsJsonStr', null);
            }}
          />
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>규정 동의 *</label>
          <label className='cursor-pointer flex gap-2 items-start'>
            <input
              type='checkbox'
              className='checkbox checkbox-primary '
              {...register('trade.regionsChecked', {
                required: {
                  value: true,
                  message: '규칙 확인은 필수입니다.',
                },
              })}
            />
            <span className='label-text text-left'>
              지역을 정확히 입력했습니다. (부정확하게 입력 시 계정이 영구
              차단됩니다.)
            </span>
          </label>
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>분양 상세 정보</label>
          <Textarea
            className='textarea-bordered min-h-[7rem]'
            placeholder='분양 정보를 입력하세요...'
            {...register('postData.desc', {
              required: {
                value: true,
                message: '분양 상세 정보는 필수입니다.',
              },
            })}
          />
        </div>

        <div className='py-3'>
          <label htmlFor=''>이미지 *</label>
          <div className='w-full flex flex-wrap'>
            {previews.map((preview, idx) => {
              return (
                <div
                  key={`post-image-${idx}`}
                  className='w-1/3 relative flex-0 p-2'
                >
                  <div className='w-0 h-0 overflow-visible absolute -top-3 right-4'>
                    <span
                      className='bg-white rounded-full block w-8 h-8'
                      onClick={() => {
                        const nImages = postData.images.filter(
                          (img, i) => i !== idx,
                        );
                        setValue('postData.images', nImages);
                        const nPreviews = postData.previews.filter(
                          (img, i) => i !== idx,
                        );
                        setValue('postData.previews', nPreviews);
                      }}
                    >
                      <IoMdCloseCircle size={32} />
                    </span>
                  </div>
                  <div className='aspect-square w-full h-full overflow-hidden flex-c'>
                    <img src={preview} className='w-full object-cover' />
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <Dropzone
              accept={{
                'image/*': [],
              }}
              multiple
              // maxFiles={leftToAdd}
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classNames('pt-4')} {...getRootProps()}>
                  <div className='flex-col gap-1'>
                    <button className='btn btn-outline gap-2'>
                      <FaPlus size={14} />{' '}
                      <span>{imgSelected ? '선택하기' : '이미지'}</span>
                    </button>

                    <div className='text-center opacity-50'>
                      <span>* 최대 이미지 갯수 {imageMaxCount}</span>
                    </div>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>

        <div className='h-12'></div>
      </div>
    </Modal>
  );
}

export default ModalTradePostForm;
