import { first, isEmpty, size, toInteger } from 'lodash';
import { memo } from 'react';
import { Waypoint } from 'react-waypoint';

import { IoFish } from 'react-icons/io5';
import appConfig from '../../app/appConfig';
import { Enum_Post_Category, usePostsQuery } from '../../generated/graphql';
import { usePageLoadMore } from '../../graphql/use-page-load-more';
import { Id } from '../users/type';

import useAppConfig from '../../app/use-app-configs';
import { useAppRouter } from '../../common/hooks/use-app-router';
import useSearchState from '../../common/hooks/use-search-state';
import LoadingIcon from '../../components/loading/LoadingIcon';
import SkelPostList from '../../components/skeleton/SkelPostList';
import { convToRender } from '../../types/gql-enhanced-types';
import { notNull } from '../../utils/array';
import useBlockState from '../report/hooks/use-block-state';
import PartnerPostListItem from './PartnerPostListItem';
import PostListItem from './PostListItem';
import PostNoticeRotate from './PostNoticeRotate';
import { getPostQueryParams } from './posts-query-params';

const { pageSize, partnerPostStep } = appConfig.post;

function PostList({
  category,
  aquaticId,
  creatorId,
}: {
  category?: Enum_Post_Category;
  aquaticId?: Id;
  creatorId?: Id;
}) {
  const [
    {
      searchState: {
        searchMap: { keywords },
      },
    },
  ] = useSearchState();
  const keyword = first(keywords) || '';

  const [, { filterBlockedPost }] = useBlockState();

  const {
    params: { boardType },
  } = useAppRouter();

  // const allPosts = boardType === undefined;

  const [{ boardConfig }] = useAppConfig({ boardConfigId: category });
  const [{ selectedTag }] = useSearchState({ boardConfig });

  const listQueryVariables = getPostQueryParams({
    creatorId,
    category: category || boardType,
    keyword,
    tags: selectedTag,
    aquaticId,
  });

  const {
    data,
    loading: loadingN,
    fetchMore,
  } = usePostsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: listQueryVariables,
  });

  const posts = (data?.posts?.data || [])
    .map(convToRender)
    .filter((i) => i?.deleted !== true)
    .filter(filterBlockedPost)
    .filter(notNull);

  const loading = loadingN && isEmpty(posts);

  const listLength = size(posts);
  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading,
    pageSize,
    listLength,
  });

  const showAllNotis =
    (boardType == null || boardType === 'all') && selectedTag == null;

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      className='flex-1 flex flex-col overflow-hidden-x'
    >
      {loading && <SkelPostList key='skel' />}

      {/* todo: show only when it is not notice board */}
      {!loading && (
        <PostNoticeRotate key={'notice-rotate'} showAll={showAllNotis} />
      )}

      {posts.map((post, idx) => {
        const addPPToList =
          idx >= partnerPostStep && idx % partnerPostStep === 0;
        const ppRenderCounter = toInteger((idx + 1) / partnerPostStep);

        let ppComp = null;
        if (addPPToList) {
          ppComp = (
            <PartnerPostListItem
              key={`post-${idx}`}
              numberFromTop={ppRenderCounter}
            />
          );
        }

        return (
          <>
            {ppComp}
            <PostListItem key={post.id} post={post} />
          </>
        );
      })}

      {!loading && (
        <div className='flex-col flex-1 flex-center'>
          <IoFish size={20} />
        </div>
      )}

      {loadingMore && (
        <div className='flex-center-x p-10'>
          <LoadingIcon />
        </div>
      )}

      {hasMore && <Waypoint scrollableAncestor='window' onEnter={loadMore} />}
    </div>
  );
}

export default memo(PostList);
