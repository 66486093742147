import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { memo, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { useShare } from '../../common/hooks/use-share';
import { IconPaperPlane } from '../../components/Icons';
import {
  useUpdatePostMutation,
  useUpdateTradeMutation,
} from '../../generated/graphql';
import { listToIds } from '../../libs/strapi/list';
import { TPost, TTrade } from '../../types/gql-enhanced-types';
import { pretty } from '../../utils/debug';
import useAuth from '../auth/use-auth';
import ModalTradePostForm from '../post/ModalTradePostForm';
import useBlockState from '../report/hooks/use-block-state';
import useGlobalState from '../report/hooks/use-global-state';

function TradeItemMenu({
  post,
  trade,
  sold,
  onBlock,
}: {
  post: TPost;
  trade: TTrade;
  sold?: boolean | null;
  onBlock?: () => void;
}) {
  const [{ isSuperAdmin, isLoggedIn, userId }] = useAuth();
  const [, { setReportState }] = useGlobalState();
  const [, { blockUserId }] = useBlockState();

  const [openAddPost, setOpenAddPost] = useState<TPost | null>(null);

  const [updateTrade] = useUpdateTradeMutation({
    refetchQueries: ['Trades', 'Posts'],
    onCompleted() {},
  });

  const [updatePost] = useUpdatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted() {},
  });

  const { shareLink } = useShare();

  const tradeCreatorId = trade?.creator?.data?.id;
  const postCreatorId = post?.creator?.data?.id;
  const isOwner = userId && userId === postCreatorId;

  if (sold && isOwner) {
    return (
      <div
        className='flex-center'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Menu
          menuButton={
            <MenuButton>
              <FiMoreVertical size={23} />
            </MenuButton>
          }
          transition
        >
          <MenuItem
            className='flex gap-2 p-0'
            onClick={() => {
              shareLink();
            }}
          >
            <IconPaperPlane /> <span>공유하기</span>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <>
      {openAddPost && (
        <ModalTradePostForm
          trade={trade}
          post={post}
          onClose={() => {
            setOpenAddPost(null);
          }}
        />
      )}

      {isLoggedIn && (
        <div
          className='flex-center'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Menu
            menuButton={
              <MenuButton>
                <FiMoreVertical size={23} />
              </MenuButton>
            }
            transition
          >
            {!isOwner && (
              <>
                <MenuItem
                  onClick={() => {
                    setReportState({
                      reportKey: `post-${post?.id}`,
                      accusedUser: postCreatorId,
                      creator: userId,
                      snapshot: pretty(post),
                      images: listToIds(post.images?.data),
                    });
                  }}
                >
                  신고하기
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const yes = window.confirm('차단할까요?');
                    if (!yes) return;

                    blockUserId(postCreatorId);
                    onBlock?.();
                  }}
                >
                  차단하기
                </MenuItem>
              </>
            )}
            {isSuperAdmin && (
              <MenuItem
                onClick={() => {
                  const yes = window.confirm('삭제 할까요?');
                  if (!yes) return;

                  if (post.id && postCreatorId) {
                    updatePost({
                      variables: {
                        id: post.id,
                        data: {
                          deleted: true,
                        },
                      },
                    });
                  }
                }}
              >
                삭제
              </MenuItem>
            )}
            {(isSuperAdmin || isOwner) && (
              <>
                <MenuItem
                  onClick={() => {
                    setOpenAddPost(post);
                  }}
                >
                  수정
                </MenuItem>
                {!sold && (
                  <MenuItem
                    onClick={() => {
                      const yes = window.confirm('판매완료 할까요?');
                      if (!yes) return;

                      if (trade.id && postCreatorId) {
                        updateTrade({
                          variables: {
                            id: trade.id,
                            data: {
                              sold: true,
                              creator: tradeCreatorId,
                            },
                          },
                        });
                      }
                    }}
                  >
                    분양완료
                  </MenuItem>
                )}
              </>
            )}
          </Menu>
        </div>
      )}
    </>
  );
}

export default memo(TradeItemMenu);
