import { memo } from 'react';
import { useNavigate, useParams } from 'react-router';

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { MdChevronLeft } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import Card from '../../components/Card';
import DeletedPage from '../../components/error/DeletedPage';
import SkelPostView from '../../components/skeleton/SkelPostView';
import { H3 } from '../../components/typographic/Headers';
import { usePostQuery } from '../../generated/graphql';
import { convToRender, TTrade } from '../../types/gql-enhanced-types';
import { getTargetId } from '../post/comment-params';
import CommentForm from '../post/CommentForm';
import Comments from '../post/Comments';
import StickyHeader from '../post/StickyHeader';
import TradeItemMenu from './TradeItemMenu';
import TradePostCard from './TradePostCard';

function TradeViewPage() {
  const { postId } = useParams();
  const nav = useNavigate();
  const [sParams] = useSearchParams();
  const navToComments = sParams.has('comm');

  const queryVariable = {
    id: postId || '',
  };
  const { data, loading } = usePostQuery({
    skip: !postId,
    variables: queryVariable,
    onCompleted: () => {
      if (navToComments) {
        setTimeout(() => {
          document.getElementById('comments')?.scrollIntoView();
        }, 200);
      }
    },
  });

  const post = convToRender(data?.post?.data);
  const trade = convToRender(post?.trades?.data?.[0]) as TTrade;

  const names = trade?.aquatic?.data?.attributes?.name;

  if (post?.deleted === true) {
    return <DeletedPage />;
  }

  if (!post || loading) {
    return <SkelPostView />;
  }

  if (isEmpty(post)) {
    return <div className='flex-center p-5'>No post</div>;
  }

  return (
    <div
      className='flex flex-col'
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <StickyHeader>
        <div
          className={classNames(
            'navbar border-b bg-base-100 max-w-2xl',
            'flex justify-between w-full p-0 border-b gap-2',
          )}
        >
          <span
            className='px-2 cursor-pointer'
            onClick={() => {
              nav(-1);
            }}
          >
            <MdChevronLeft className='' size={24} />
          </span>
          <H3
            className='flex-1 flex flex-center-y gap-2 '
            onClick={() => {
              nav(-1);
            }}
          >
            {trade.sold && (
              <div className='flex'>
                <span
                  className={classNames(
                    'flex-center gap-1 flex-nowrap p-2 rounded-full',
                    'whitespace-nowrap',
                    'bg-green-300 text-black font-bold',
                  )}
                >
                  <FaRegCircleCheck size={14} />
                </span>
              </div>
            )}
            {post?.title || names}
          </H3>
          <div className='flex-none px-2'>
            <TradeItemMenu trade={trade} post={post} sold={trade.sold} />
          </div>
        </div>
      </StickyHeader>

      <div className='flex flex-col gap-[20px]'>
        <TradePostCard trade={trade} post={post} />

        {/* comments */}

        <Card id='comments' className='gap-8 overflow-hidden pr-0 lg:pr-0'>
          <h3 className='font-bold text-2xl pr-[15px] lg:pr-[30px]'>댓글</h3>
          <CommentForm targetId={getTargetId({ post, target: 'post' })} />
          {/* comment list */}
          <Comments targetId={getTargetId({ post, target: 'post' })} />
        </Card>

        <div className='h-20'></div>
      </div>
    </div>
  );
}

export default memo(TradeViewPage);
