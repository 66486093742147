/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import { memo, useState } from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import useCopyClipboard from 'react-use-clipboard';
import appConfig from '../../app/appConfig';
import Card from '../../components/Card';
import { IconPaperPlane } from '../../components/Icons';
import { convToRender, TPost, TTrade } from '../../types/gql-enhanced-types';
import { dateDistance } from '../../utils/date';
import InlineGallery from '../post/InlineGallery';
import ModalTradePostForm from '../post/ModalTradePostForm';
import PostLikeButton from '../post/PostLikeButton';
import UserContentRenderer from '../post/UserContentRenderer';
import ProfileUser from '../users/ProfileUser';

function TradePostCard({ trade, post }: { trade: TTrade; post: TPost }) {
  const [openAddPost, setOpenAddPost] = useState<TPost | null>(null);

  const [linkCopied, setLinkCopied] = useCopyClipboard(
    `${window.location.origin}/posts/trade/${post?.id}`,
    {
      successDuration: appConfig.post.showCopyMessageDuration,
    },
  );

  const images = post?.images?.data || [];

  const aquatic = convToRender(trade.aquatic?.data);
  const total = (trade.quantity || 1) * (trade.price || 1);

  return (
    <Card className='gap-6 rounded-none shadow-lg'>
      {openAddPost && (
        <ModalTradePostForm
          trade={trade}
          post={post}
          onClose={() => {
            setOpenAddPost(null);
          }}
        />
      )}

      <div className='flex justify-between gap-2'>
        <div>
          <ProfileUser user={trade?.creator?.data} />
        </div>
        <div className='flex flex-none gap-2 text-sm'>
          <div className='opacity-50'>{dateDistance(post?.createdAt)}</div>
        </div>
      </div>

      <div className='flex-col gap-1'>
        <div className='flex flex-wrap gap-2'>
          <div className='flex-col flex-1 gap-2 flex-nowrap'>
            <div className='text-xl font-bold'>
              {aquatic?.name} [{trade.quantity} 마리]
            </div>
            {trade.sold && (
              <div className='flex'>
                <span
                  className={classNames(
                    'flex-center gap-1 flex-nowrap px-2 py-1 text-sm rounded-lg',
                    'whitespace-nowrap',
                    'bg-green-300 text-black font-bold',
                  )}
                >
                  <FaRegCircleCheck size={14} />
                  분양완료
                </span>
              </div>
            )}
          </div>
          <div className='flex justify-end gap-2 flex-nowrap'>
            <div className='flex-col items-end justify-between'>
              <span className='text-xl font-bold whitespace-nowrap'>
                ₩ {total.toLocaleString()}
              </span>
              <span className='gap-2 whitespace-nowrap opacity-70'>
                {trade.quantity} {' X '} {trade.price?.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>

      <UserContentRenderer text={post?.desc} />

      <InlineGallery images={images} />

      {/* user actions */}
      <div className='flex gap-3 flex-center'>
        <PostLikeButton
          post={{
            ...post,
            creator: trade.creator,
          }}
        />
        <div className='flex gap-2 cursor-pointer'>
          <button
            className='border-gray-400 rounded-full btn btn-outline'
            onClick={async () => {
              setLinkCopied();

              if (navigator.canShare && navigator.share) {
                const shareData = {
                  url: `${window.location.origin}/posts/trade/${post.id}`,
                };
                const share = navigator.canShare(shareData);

                if (share) {
                  await navigator.share(shareData);
                }
              }
            }}
          >
            <IconPaperPlane /> <span>공유하기</span>
          </button>
          <div
            className={classNames('relative h-0 -top-1', {
              hidden: !linkCopied,
            })}
          >
            <div className='absolute px-2 py-1 border rounded whitespace-nowrap'>
              링크 복사됨
            </div>
          </div>
        </div>
      </div>

      <div className=''></div>
    </Card>
  );
}

export default memo(TradePostCard);
