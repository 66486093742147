import { useState } from 'react';

import { IoFishOutline } from 'react-icons/io5';
import { MdAdd, MdOutlineSell } from 'react-icons/md';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import LogoBlinkerCenter from '../components/loading/LogoBlink';
import { Enum_Post_Category } from '../generated/graphql';
import useAuth from '../modules/auth/use-auth';
import ModalPostForm from '../modules/post/ModalPostForm';
import ModalPostSocialForm from '../modules/post/ModalPostSocialForm';
import ModalTradePostForm from '../modules/post/ModalTradePostForm';
import SignInModalWrap from '../modules/users/SignInModal';
import AdminToolsModal from './AdminToolsModal';
import './global-action-menu.scss';

function GlobalActionMenu() {
  const [
    { isSuperAdmin, isShadow, isLoggedIn, authInit, loading, isPartner1 },
  ] = useAuth();

  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [openAdminTool, setOpenAdminTool] = useState(false);
  // const [, { setGlobalState }] = useGlobalState();

  const [addPostCategory, setAddPostCategory] =
    useState<Enum_Post_Category | null>(null);

  if (!authInit || loading) {
    return (
      <div className='justify-center flex-1'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <>
      <Fab icon={<MdAdd size={35} className='' />} alwaysShowTitle>
        <Action
          text='자유게시판'
          onClick={() => {
            if (!isLoggedIn) {
              setAuthModalOpen(true);
              return;
            }

            setAddPostCategory(Enum_Post_Category.Main);
          }}
        >
          <IoFishOutline />
        </Action>
        <Action
          text='고민상담'
          onClick={() => {
            if (!isLoggedIn) {
              setAuthModalOpen(true);
              return;
            }

            setAddPostCategory(Enum_Post_Category.Qna);
          }}
        >
          <RiQuestionnaireLine />
        </Action>

        <Action
          text='분양하기'
          onClick={() => {
            if (!isLoggedIn) {
              setAuthModalOpen(true);
              return;
            }

            setAddPostCategory(Enum_Post_Category.Trade);

            // if (isSuperAdmin || isShadow) {
            //   return;
            // }
            // toast.error('모바일 앱을 설치하여 사용해주세요.', {
            //   duration: 5 * 1000,
            //   position: 'top-center',
            // });

            // setGlobalState((st) =>
            //   produce(st, (dr) => {
            //     dr.openDownload = true;
            //   }),
            // );
          }}
        >
          <MdOutlineSell />
        </Action>
        <Action
          text='소셜미디어'
          onClick={() => {
            if (!isLoggedIn) {
              setAuthModalOpen(true);
              return;
            }

            setAddPostCategory(Enum_Post_Category.Social);
          }}
        >
          <IoFishOutline />
        </Action>
        {(isShadow || isSuperAdmin) && (
          <Action
            text='관리자'
            onClick={() => {
              if (!isLoggedIn) {
                setAuthModalOpen(true);
                return;
              }

              setOpenAdminTool(true);
            }}
          >
            <IoFishOutline />
          </Action>
        )}
        {(isPartner1 || isSuperAdmin) && (
          <Action
            text='공지사항'
            onClick={() => {
              if (!isLoggedIn) {
                setAuthModalOpen(true);
                return;
              }

              setAddPostCategory(Enum_Post_Category.Notice);
            }}
          >
            <MdOutlineSell />
          </Action>
        )}
      </Fab>
      {addPostCategory === Enum_Post_Category.Social && (
        <ModalPostSocialForm
          category={addPostCategory}
          onSuccess={() => {
            setAddPostCategory(null);
          }}
          onClose={() => {
            setAddPostCategory(null);
          }}
        />
      )}
      {(addPostCategory === Enum_Post_Category.Main ||
        addPostCategory === Enum_Post_Category.Notice ||
        addPostCategory === Enum_Post_Category.Qna) && (
        <ModalPostForm
          category={addPostCategory}
          onSuccess={() => {
            setAddPostCategory(null);
          }}
          onClose={() => {
            setAddPostCategory(null);
          }}
        />
      )}
      {addPostCategory === Enum_Post_Category.Trade && (
        <ModalTradePostForm
          category={addPostCategory}
          onSuccess={() => {
            // if (!isMarketRoute) {
            //   nav('/trades');
            // }

            setAddPostCategory(null);
          }}
          onClose={() => {
            setAddPostCategory(null);
          }}
        />
      )}

      {authModalOpen && <SignInModalWrap open setOpen={setAuthModalOpen} />}
      {openAdminTool && <AdminToolsModal setOpen={setOpenAdminTool} />}

      <div className='h-20'></div>
    </>
  );
}

export default GlobalActionMenu;
